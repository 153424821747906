import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import GithubIcon from "@material-ui/icons/GitHub";
import "../styles/Home.css";

function Home() {
  return (
    <div className='home'>
      <div className='about'>
        <h2> Hi, My Name is Jacques</h2>
        <div className='prompt'>
          <p>A software developer with a passion for learning and creating.</p>
          <LinkedInIcon
            style={{ cursor: "pointer" }}
            aria-label='linkedIn.com'
            onClick={() => window.open("https://www.linkedin.com/in/jsovoessi")}
          />
          <a href='mailto:jacques.sovoessi@gmail.com'>
            <EmailIcon />
          </a>
          <GithubIcon
            style={{ cursor: "pointer" }}
            aria-label='github.com'
            onClick={() => window.open("https://github.com/sovoessi")}
          />
        </div>
      </div>
      <div className='skills'>
        <h1> Skills</h1>
        <ol className='list'>
          <li className='item'>
            <h2> Front-End</h2>
            <span>
              ReactJS, BootStrap 3 - 4 - 5, HTML, CSS, NPM, MaterialUI, BULMA
              TailwindCSS, StyledComponents
            </span>
          </li>
          <li className='item'>
            <h2>Back-End</h2>
            <span>
              Java Spring, NodeJS, ExpressJS, Php Symfony 4, MySQL, Postgres,
              SQLite, MongoDB, Firebase, DigitalOcean, AWS S3, Android
            </span>
          </li>
          <li className='item'>
            <h2>Languages</h2>
            <span>Java, Kotlin, Python, JavaScript, Php, C, C++, C# </span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;
