import Proj1 from "../assets/file-extensions-on-a-blue-background.webp";
import Proj2 from "../assets/rest-api.png";
import Proj3 from "../assets/tombook2.png";
import Proj4 from "../assets/contact-icon.png";
import Proj5 from "../assets/jenny.png";

export const ProjectList = [
  {
    name: "Directory Files to JSON ",
    image: Proj1,
    skills: "Java, JSON",
    githubLink: "https://github.com/sovoessi/CreatePDF_ENUM_JsonFile",
  },
  {
    name: "JSON to Database / Rest API",
    image: Proj2,
    skills: "Spring boot, MongoDB",
    githubLink: "https://github.com/sovoessi/CreateFromJSONToDB",
  },
  {
    name: "Tombook2, e-library",
    image: Proj3,
    skills: "ReactJS, Google API",
    githubLink: "https://github.com/sovoessi/Tombook2",
    url: "https://my-library-tombook2.netlify.app",
  },
  {
    name: "Contact Manager",
    image: Proj4,
    skills: "Spring Boot, ReactJS, MongoDB",
    githubLink: "https://github.com/sovoessi/Create-Contact-App-Spring",
    url: "https://personal-rolodex-contact-manager.netlify.app",
  },
  {
    name: "Jenny Lingoni",
    image: Proj5,
    skills: "Firebase, React JS",
    url: "https://jenny-lingoni.netlify.app",
  },
];
