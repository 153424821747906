import React from "react";
import GithubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className='footer'>
      <div className='socialMedia'>
        <LinkedInIcon
          style={{ cursor: "pointer" }}
          aria-label='linkedIn.com'
          onClick={() => window.open("https://www.linkedin.com/in/jsovoessi")}
        />
        <a href='mailto:jacques.sovoessi@gmail.com'>
          <EmailIcon />
        </a>

        <GithubIcon
          style={{ cursor: "pointer" }}
          aria-label='github.com'
          onClick={() => window.open("https://github.com/sovoessi")}
        />
      </div>
      <p> &copy; 2022 dahomeykid.com</p>
    </div>
  );
}

export default Footer;
