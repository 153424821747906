import React from "react";
import { useParams } from "react-router-dom";
import { ProjectList } from "../helpers/ProjectList";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkIcon from "@material-ui/icons/Link";

import "../styles/ProjectDisplay.css";

function ProjectDisplay() {
  const { id } = useParams();
  const project = ProjectList[id];
  return (
    <div className='project' key={project.id}>
      <h1> {project.name}</h1>

      <img style={{ width: "30%" }} src={project.image} alt={project.id} />
      <p>
        <b>Skills:</b> {project.skills}
      </p>
      <div className='socialMedia'>
        {project.githubLink && (
          <GitHubIcon
            style={{ cursor: "pointer" }}
            aria-label='github.com'
            onClick={() => window.open(`${project.githubLink}`)}
          />
        )}

        {project.url && (
          <LinkIcon
            style={{ cursor: "pointer" }}
            onClick={() => window.open(`${project.url}`)}
          />
        )}
      </div>
    </div>
  );
}

export default ProjectDisplay;
